
export default {
    data( ) {
        const defaultSort = { value: 'asc', 'display': this.$t( '_common:terms.sortAlphabetical_abv' ) };

        return {
            filtersTab: {
                event: {
                    city: null,
                },
                artist: {
                    category: null,
                }
            },
            sortTabs: {
                artist: {
                    value: defaultSort,
                    options: [
                        defaultSort,
                        { value: 'desc', 'display': this.$t( '_common:terms.sortAlphabeticalDesc_abv' ) },
                    ]
                }
            }
        };
    },
    computed: {
        filterTabByRoute( ) {
            if ( this.$route.name === 'userFavorites.events' ) {
                return this.filtersTab.event;
            }
            return this.filtersTab.artist;
        },
        sortTabByRoute( ) {
            if ( this.$route.name === 'userFavorites.events' ) {
                return this.sortTabs.event;
            }
            return this.sortTabs.artist;

        }
    }
};